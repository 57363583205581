import { ActionUserConfirmationDialogComponent } from './../action-user-confirmation-dialog/action-user-confirmation-dialog.component';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MovanoService } from 'src/app/movano.service';
import { RecoverPasswordComponent } from 'src/app/login/recover-password/recover-password.component';
import { DeleteUserAdminComponent } from '../delete-user-admin/delete-user-admin.component';

@Component({
  selector: 'app-create-edit-admin-user',
  templateUrl: './create-edit-admin-user.component.html',
  styleUrls: ['./create-edit-admin-user.component.scss']
})
export class CreateEditAdminUserComponent {
  protected isEditMode: boolean = false;
  selectedRole: string = '';
  isDropdownOpen: boolean = false;
  isVisible = false;
  adminUserData: any = {
    id:'',
    name: '',
    role: '',
    email: '',
    password: '',
  };
  errors: any = {
    name: '',
    familyName: '',
    phoneNumber: '',
    email: '',
    password: '',
  };

  @Output() closeHimself = new EventEmitter<boolean>();

  constructor(
    protected dialog: MatDialog,
    private dialogRef: MatDialogRef<CreateEditAdminUserComponent>,
    protected movanoService: MovanoService,
    @Inject(MAT_DIALOG_DATA) public data: { isEditMode: boolean; adminUserData?: any }
  ) {
    this.isEditMode = data.isEditMode;
    this.adminUserData = {
      ...this.adminUserData,
      ...data.adminUserData,
      adminUserName: data.adminUserData?.firstName || '',
    };
    console.log(this.data);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  createAdminUser(): void {
    const newUserData = {
      newUser: {
        email: this.adminUserData.email,
        name: this.adminUserData.name,
        familyName: this.adminUserData.familyName,
        phoneNumber: this.adminUserData.phoneNumber,
        password: this.adminUserData.password,
        accountType: this.selectedRole || 'customerSupport',
      }
    };

    this.movanoService.postAdminUser(newUserData).subscribe({

      next: () => {
        this.dialogRef.close();

        this.dialog.open(ActionUserConfirmationDialogComponent, {
          width: '356px',
          data: { isEditMode: false, isAddUser: true }
        });
      },
      error: (err) => {
        console.error('Error al crear lel user:', err);
      }
    });
  }

  updateUser(): void {
    if (!this.adminUserData?.userUuid) return;

    const updatedData: any = {
      id: this.adminUserData?.userUuid,
      email: this.adminUserData.email,
      name: this.adminUserData.name,
      familyName: this.adminUserData.familyName,
      phone: this.adminUserData.phone,
      accountType: this.selectedRole ,
      enabled: this.adminUserData.enabled,
    };

    const changedFields = this.getChangedFields(this.data.adminUserData, updatedData);

    if (Object.keys(changedFields).length > 0) {
    this.movanoService.patchAdminUser(changedFields, this.adminUserData.userUuid).subscribe({
      next: () => {
        this.dialogRef.close();
        this.dialog.open(ActionUserConfirmationDialogComponent, {
          width: '356px',
          data: { isEditMode: true , isAddUser: true}
        });
      },
      error: (err) => {
        console.error('Error al actualizar la organización:', err);
      }
    });
  }
  }

  validateField(field: string) {
    switch (field) {
      case 'name':
        this.errors.name =
          this.adminUserData.name.length < 3
            ? 'First name must be at least 3 characters long.'
            : '';
        break;
      case 'familyName':
        this.errors.familyName =
          this.adminUserData.familyName.length < 3
            ? 'Last name must be at least 3 characters long.'
            : '';
        break;
      case 'phoneNumber':
        this.errors.phoneNumber = !/^\+\d{9,}$/.test(this.adminUserData.phoneNumber)
          ? 'Phone must start with "+" and contain at least 11 digits.'
          : '';
        break;
      case 'email':
        this.errors.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.adminUserData.email)
          ? 'Email must be valid.'
          : '';
        break;
      case 'password':
        this.errors.password = !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(this.adminUserData.password)
          ? 'Password must contain at least 1 uppercase, 1 lowercase, 1 digit, 1 symbol, and be 8 characters long.'
          : '';
        break;
    }
  }

  private getChangedFields(originalData: any, updatedData: any): any {
    const changedFields: any = {};

    Object.keys(updatedData).forEach((key) => {
      if (originalData[key] !== updatedData[key]) {
        changedFields[key] = updatedData[key];
      }
    });

    return changedFields;
  }

  deleteUser(): void {
    this.dialogRef.close();
    console.log(this.adminUserData)
    this.dialog.open(DeleteUserAdminComponent, {

      width: '364px',
      data: {
        isDeletedMode: true, adminUser: this.adminUserData
      }
    });
  }

  getBadgeColor(status: string): string {

    if (status === "true") {
      return "movanoTable_Obj_Badge_blueDark";
    } else if (status === "false") {
      return "movanoTable_Obj_Badge_Deleted";
    } else {
      return status;
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectRole(role: string) {
    this.selectedRole = role;
    this.isDropdownOpen = false;
  }
  togglePasswordVisibility() {
    this.isVisible = !this.isVisible;
  }

  maskPassword(password: string): string {
    return '*'.repeat(password.length);
  }

  resetPass() {
    this.closeHimself.emit();
    this.dialog.open(RecoverPasswordComponent);
  }

  generatePassword(): void {
    const length = 12;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    this.adminUserData.password = password;
  }

}
