<div class="container">
  <div
    [ngStyle]="{
      display: 'flex',
      'justify-content': 'end',
      position: 'relative',
      left: '20px',
      top: '-5px',
      opacity: '0.5'
    }"
  >
    <mat-icon (click)="closeDialog()" [ngStyle]="{ cursor: 'pointer' }">close</mat-icon>
  </div>
  <p class="title">{{ isEditMode ? "Edit user" : "New user" }}</p>

  <div class="form-group">
    <p>First name</p>
    <input
      type="text"
      placeholder="Write the first name"
      [(ngModel)]="adminUserData.name"
      (blur)="validateField('name')"
    />
    <p *ngIf="errors.name" class="error" style="color: red;">{{ errors.name }}</p>

    <p>Last name</p>
    <input
      type="text"
      placeholder="Write the last name"
      [(ngModel)]="adminUserData.familyName"
      (blur)="validateField('familyName')"
    />
    <p *ngIf="errors.familyName" class="error" style="color: red;">{{ errors.familyName }}</p>

    <p>Role</p>
    <div class="custom-select">
      <div class="input-container">
        <input
          type="text"
          [(ngModel)]="selectedRole"
          [placeholder]="adminUserData.accountType ? adminUserData.accountType : 'Select an option'"
          readonly
          required
          (click)="toggleDropdown()"
        />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="input-icon">
          <path d="M7 10l5 5 5-5z" />
        </svg>
      </div>

      <ul *ngIf="isDropdownOpen" class="options">
        <li (click)="selectRole('admin')">Admin</li>
        <li (click)="selectRole('customerSupport')">Customer Support</li>
        <li (click)="selectRole('management')">Management</li>
      </ul>
    </div>



    <p>Phone</p>
    <input
      type="text"
      placeholder="+xxxxxxxxxxx"
      [(ngModel)]="adminUserData.phoneNumber"
      (blur)="validateField('phoneNumber')"
    />
    <p *ngIf="errors.phoneNumber" class="error" style="color: red;">{{ errors.phoneNumber }}</p>

    <p>Email</p>
    <input
      type="email"
      placeholder="xxx@xxx.com"
      [(ngModel)]="adminUserData.email"
      (blur)="validateField('email')"
    />
    <p *ngIf="errors.email" class="error" style="color: red;">{{ errors.email }}</p>

    <p *ngIf="!isEditMode">Password</p>

    <div *ngIf="!isEditMode" style="position: relative; width: 100%">
      <input
        type="text"
        placeholder="Enter a password"
        [(ngModel)]="adminUserData.password"
        (blur)="validateField('password')"
        style="width: 100%; padding-right: 70px"
        [value]="isVisible ? maskPassword(adminUserData.password) : adminUserData.password"
      />
      <p *ngIf="errors.password" class="error" style="color: red;">{{ errors.password }}</p>
      <svg
      *ngIf="!isVisible"
        (click)="togglePasswordVisibility()"
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="position: absolute; right: 25%; top: 28%; cursor: pointer"
      >
        <path
          d="M8.00033 0C4.66699 0 1.82033 2.07333 0.666992 5C1.82033 7.92667 4.66699 10 8.00033 10C11.3337 10 14.1803 7.92667 15.3337 5C14.1803 2.07333 11.3337 0 8.00033 0ZM8.00033 8.33333C6.16033 8.33333 4.66699 6.84 4.66699 5C4.66699 3.16 6.16033 1.66667 8.00033 1.66667C9.84033 1.66667 11.3337 3.16 11.3337 5C11.3337 6.84 9.84033 8.33333 8.00033 8.33333ZM8.00033 3C6.89366 3 6.00033 3.89333 6.00033 5C6.00033 6.10667 6.89366 7 8.00033 7C9.10699 7 10.0003 6.10667 10.0003 5C10.0003 3.89333 9.10699 3 8.00033 3Z"
          [attr.fill]="'#054280'"
        />
        </svg>
        <svg
        *ngIf="isVisible"
        (click)="togglePasswordVisibility()"
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="position: absolute; right: 25%; top: 28%; cursor: pointer"
      >
        <path
          d="M8.00033 0C4.66699 0 1.82033 2.07333 0.666992 5C1.82033 7.92667 4.66699 10 8.00033 10C11.3337 10 14.1803 7.92667 15.3337 5C14.1803 2.07333 11.3337 0 8.00033 0ZM8.00033 8.33333C6.16033 8.33333 4.66699 6.84 4.66699 5C4.66699 3.16 6.16033 1.66667 8.00033 1.66667C9.84033 1.66667 11.3337 3.16 11.3337 5C11.3337 6.84 9.84033 8.33333 8.00033 8.33333ZM8.00033 3C6.89366 3 6.00033 3.89333 6.00033 5C6.00033 6.10667 6.89366 7 8.00033 7C9.10699 7 10.0003 6.10667 10.0003 5C10.0003 3.89333 9.10699 3 8.00033 3Z"
          fill="#054280"
        />
        <line x1="1" y1="9" x2="15" y2="1" stroke="#054280" stroke-width="1.5" />
      </svg>
      <span
        (click)="generatePassword()"
        style="
          position: absolute;
          right: 3%;
          font-size: 12px;
          top: 20%;
          color: #1a8cff;
          cursor: pointer;
          font-weight: bold;
        "
      >
        Generate
      </span>
    </div>

    <p
      *ngIf="isEditMode"
      [ngStyle]="{ color: '#1a8cff', cursor: 'pointer', 'text-align': 'center', 'margin-bottom': '3vh' }"
      (click)="resetPass()"
    >
      Reset Password
    </p>
  </div>
  <div style="margin-bottom: 2.5vh">
    <app-main-button
      [label]="isEditMode ? 'Save changes' : 'Add user'"
      [fullWidthMarginBottom]="true"
      (click)="isEditMode ? updateUser() : createAdminUser()"
      style="margin-bottom: 10px"

    ></app-main-button>

    <app-main-button *ngIf="isEditMode" label="Delete user" [fullWidth]="true" color="red" (click)="deleteUser()"></app-main-button>
  </div>
</div>
    <!-- <p *ngIf="isEditMode">Status</p>
    <div *ngIf="isEditMode" class="status-options">
      <label class="custom-radio" *ngFor="let enabled of ['enabled', 'disabled']">
        <input type="radio" [(ngModel)]="adminUserData.enabled" [value]="enabled" required />
        <span class="checkmark">✔</span>
        <div class="movanoTable_Obj_Badge" [ngClass]="getBadgeColor(enabled.toLowerCase())">
          {{ enabled }}
        </div>
      </label>
    </div> -->
