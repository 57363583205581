import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MainServiceService } from "src/app/main-service.service";
import { MovanoService } from "src/app/movano.service"; "src/app/shared/movano-interfaces";

@Component({
  selector: 'app-action-user-confirmation-dialog',
  templateUrl: './action-user-confirmation-dialog.component.html',
  styleUrls: ['./action-user-confirmation-dialog.component.scss']
})
export class ActionUserConfirmationDialogComponent {
  protected isEditMode: boolean = false;
  protected isRecoverUserQuestionMode: boolean = false;
  protected isDeletedMode: boolean = false;
  protected isRecoverMode: boolean = false;
  protected isAddUser: boolean = false;
  adminUserData: any;
  userName: string='';

  @Input() isRecoverQuestion: boolean = false;
  @Input() isRecover: boolean = false;

  constructor(
    protected dialog: MatDialog,
    private dialogRef: MatDialogRef<ActionUserConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isEditMode: boolean;
      isRecoverUserQuestionMode: boolean;
      isRecoverMode: boolean;
      isDeletedMode: boolean;
      adminUserData?: any;
      isAddUser: boolean;
    },
    private router: Router,
    protected movanoService: MovanoService,
    private dialogCommunicationService: MainServiceService
  ) {
    this.isEditMode = data.isEditMode;
    this.isRecoverUserQuestionMode = data.isRecoverUserQuestionMode;
    this.isRecoverMode = data.isRecoverMode;
    this.isDeletedMode = data.isDeletedMode;
    this.adminUserData = data.adminUserData || {};
    this.isAddUser = data.isAddUser
    if (this.isRecoverUserQuestionMode) this.userName = data.adminUserData.name || {};
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  get message(): string {
    if (this.isEditMode) return "User updated!";
    if (this.isDeletedMode) return "User deleted!";
    if (this.isRecoverUserQuestionMode) return `Are you sure you want to recover ${this.userName}?`;
    if (this.isRecoverMode) return "User recovered!";
    if (this.isAddUser) return "Movano user created!"
    if (this.isAddUser && this.isEditMode) return "Movano user updated!"
    return "User created!";
  }

  get labelButton(): string {
    if (this.isRecoverUserQuestionMode) return "Recover User";
    if (this.isAddUser) return "Back to Movano users"
    return "Back to Users";
  }

  toggleRecoverMode(): void {
    if (this.isRecoverUserQuestionMode) {
      const updatedData: any = {
        status: 'enabled'
      };

      this.movanoService.patchAdminUser(updatedData, this.adminUserData.userUuid).subscribe({
        next: () => {
          this.dialogRef.close();
          this.dialog.open(ActionUserConfirmationDialogComponent, {
            data: {
              width: "356px",
              isRecoverMode: true,
            },
          });
          this.dialogCommunicationService.emitAdminUserUpdated();
        },
        error: (err) => {
          console.error('Error updating user:', err);
        }
      });

    } else {
      this.dialogRef.close();
      this.router.navigate(["admin-users"]);
    }
  }

  handleAddUserClick(): void {
    this.closeDialog();
    this.router.navigate(['admin-users']);
  }
}

