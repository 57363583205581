<!-- <div class="movanoHeader" [ngClass]="{'movanoDark': darkMode}">
  <div class="movanoHeader_Tittle">
    <svg
    class="movanoHeader_Tittle_btn"
    width="max(1.3889vw, 20px)" height="max(1.11vw, 16px)"
    (click)="menuDisplay = true" viewBox="0 0 20 16" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Group 567">
      <path id="Vector 19" d="M0 6.5L14.4375 6.5" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"/>
      <path id="Vector 21" d="M0 1L14.4375 0.999999" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"/>
      <path id="Vector 20" d="M0 12L8.25 12" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"/>
      </g>
    </svg>

    <span class="movanoHeader_Tittle_txt">{{LITERALS.DEVICES.TITTLE}}</span> -->

<!--     <svg class="movanoHeader_Tittle_btn" width="max(1.11vw, 16px)" height="max(1.11vw, 16px)" *ngIf="!searchDisplay"
      (click)="searchDisplay = true" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
        fill="var(--main-blue-dark)" />
    </svg>
    <div *ngIf="searchDisplay" class="movanoHeader_Tittle_Search scale-up-hor-left">
      <svg (click)="search($event, 'device_name', deviceTable)" width="max(1.11vw, 16px)" height="max(1.11vw, 16px)"
        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
          fill="var(--main-blue-dark)" />
      </svg>
      <span class="p-input-icon-left">
        <input class="movanoInputSearch" type="text" pInputText (input)="search($event, 'device_name', deviceTable)" />
      </span>
      <svg width="max(0.833vw, 16px)" height="max(0.833vw, 16px)"
        (click)="deviceTable.reset(); searchDisplay = false;" viewBox="0 0 12 12" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE" />
      </svg>
    </div>

    <svg (click)="filterDisplay = true" class="movanoHeader_Tittle_btn" width="max(0.9vw, 16px)"
      height="max(0.9vw, 16px)" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.364543 1.48084C0.443709 1.58375 4.90871 7.28375 4.90871 7.28375V12.0417C4.90871 12.4771 5.26496 12.8333 5.70829 12.8333H7.29954C7.73496 12.8333 8.09913 12.4771 8.09913 12.0417V7.27584C8.09913 7.27584 12.4454 1.71834 12.6512 1.465C12.857 1.21167 12.8333 0.958338 12.8333 0.958338C12.8333 0.522922 12.477 0.166672 12.0337 0.166672H0.966209C0.483293 0.166672 0.166626 0.546672 0.166626 0.958338C0.166626 1.11667 0.214126 1.30667 0.364543 1.48084Z"
        fill="var(--main-blue-dark)" />
    </svg> -->
<!--   </div>
  <div class="movanoHeader_Logo">
    <img class="movanoHeader_Logo_IMG"
      src="{{ LITERALS.MOVANO.IMG_HEADER_DARK}}"> -->
<!--     <span class="movanoHeader_Version">v{{LITERALS.MOVANO.VERSION}}</span> -->
<!--   </div>
  <div class="movanoHeader_Functions">
    <div class="movano_switch" style="position: relative; ">
      <label class="switch">
        <input [checked]="darkMode" type="checkbox" (change)="changeTheme()">
        <span class="slider round"></span>
      </label>
    </div>
    <svg class="movanoHeader_Functions_Icon" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.77761 8.76924C5.77761 5.86289 7.56864 3.37988 10.1005 2.36225C10.825 2.06917 10.7111 0.986418 9.94582 0.815457C9.0503 0.620072 8.0978 0.571226 7.11274 0.701482C3.44114 1.18994 0.502227 4.21026 0.119599 7.89001C-0.385145 12.7828 3.433 16.9103 8.21992 16.9103C8.81421 16.9103 9.38408 16.8451 9.94582 16.723C10.7192 16.5521 10.8413 15.4774 10.1086 15.1762C7.48723 14.126 5.76947 11.586 5.77761 8.76924Z"
        [ngStyle]="{fill : darkMode ? '#FFFFFF' : 'var(--main-blue-dark)', boxShadow: darkMode ? '0px 0px  5px rgba(255, 255, 255, 0.5)' : 'none'}" />
    </svg>

    <div (click)="userDisplay = true" class="movanoHeader_userIcon">
      <svg width="max(1.18vw, 17px)" height="max(1.32vw, 19px)" viewBox="0 0 17 19" fill="#FFFFFF"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5 7.91667C10.6327 7.91667 12.3482 6.14333 12.3482 3.95833C12.3482 1.77333 10.6327 0 8.5 0C6.36727 0 4.63636 1.77333 4.63636 3.95833C4.63636 6.14333 6.36727 7.91667 8.5 7.91667ZM8.5 11.0833C5.67182 11.0833 0 12.54 0 15.4375V17.4167C0 18.2875 0.695454 19 1.54545 19H15.4545C16.3045 19 17 18.2875 17 17.4167V15.4375C17 12.54 11.3282 11.0833 8.5 11.0833Z" />
      </svg>
    </div>
  </div>
</div> -->
<app-header class="fixed-header" [title]="LITERALS.DEVICES.TITTLE"></app-header>
<div class="movanoHeaderMovil fixed-sidebar" [ngClass]="{ movanoDark: darkMode }">
  <div class="movanoHeader_Tittle">
    <svg
      class="movanoHeader_Tittle_btn"
      (click)="menuDisplay = true"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 8L18.375 8" stroke="#284767" stroke-width="2" stroke-linecap="round" />
      <path d="M0 1L18.375 0.999998" stroke="#284767" stroke-width="2" stroke-linecap="round" />
      <path d="M0 15H18" stroke="#284767" stroke-width="2" stroke-linecap="round" />
    </svg>
    <img
      class="movanoHeader_Logo_IMG"
      src="{{ darkMode ? LITERALS.MOVANO.IMG_HEADER_DARK : LITERALS.MOVANO.IMG_HEADER }}"
    />
    <span class="movanoHeader_Version">v{{ LITERALS.MOVANO.VERSION }}</span>

    <div (click)="userDisplay = true" class="movanoHeader_userIcon">
      <svg
        width="max(1.18vw, 17px)"
        height="max(1.32vw, 19px)"
        viewBox="0 0 17 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.5 7.91667C10.6327 7.91667 12.3482 6.14333 12.3482 3.95833C12.3482 1.77333 10.6327 0 8.5 0C6.36727 0 4.63636 1.77333 4.63636 3.95833C4.63636 6.14333 6.36727 7.91667 8.5 7.91667ZM8.5 11.0833C5.67182 11.0833 0 12.54 0 15.4375V17.4167C0 18.2875 0.695454 19 1.54545 19H15.4545C16.3045 19 17 18.2875 17 17.4167V15.4375C17 12.54 11.3282 11.0833 8.5 11.0833Z"
        />
      </svg>
    </div>
  </div>
  <div class="movanoHeader_Tittle">
    <span class="movanoHeader_Tittle_txt">{{ LITERALS.DEVICES.TITTLE }}</span>
    <div style="display: flex; align-items: center; gap: 20px">
      <app-calendar
        #list_calendar
        (initGetInfo)="emitGetInfo($event)"
        [slctdDateOpt]="selectedDate"
        [timeZones]="timeZones"
      ></app-calendar>
      <svg
        class="movanoHeader_Tittle_btn"
        *ngIf="!searchDisplay"
        (click)="searchDisplay = true"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
          fill="var(--main-blue-dark)"
        />
      </svg>
      <div *ngIf="searchDisplay" class="movanoHeader_Tittle_Search scale-up-hor-left">
        <svg
          (click)="search($event, 'device_name', deviceTable)"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
            fill="var(--main-blue-dark)"
          />
        </svg>
        <span class="p-input-icon-left">
          <input
            class="movanoInputSearch"
            type="text"
            pInputText
            (input)="search($event, 'device_name', deviceTable)"
          />
        </span>

        <svg
          (click)="deviceTable.reset(); searchDisplay = false; this.isFiltered = false"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
            fill="#C4D9EE"
          />
        </svg>
      </div>

      <!-- <svg
        (click)="filterDisplay = true"
        class="movanoHeader_Tittle_btn"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.364543 1.48084C0.443709 1.58375 4.90871 7.28375 4.90871 7.28375V12.0417C4.90871 12.4771 5.26496 12.8333 5.70829 12.8333H7.29954C7.73496 12.8333 8.09913 12.4771 8.09913 12.0417V7.27584C8.09913 7.27584 12.4454 1.71834 12.6512 1.465C12.857 1.21167 12.8333 0.958338 12.8333 0.958338C12.8333 0.522922 12.477 0.166672 12.0337 0.166672H0.966209C0.483293 0.166672 0.166626 0.546672 0.166626 0.958338C0.166626 1.11667 0.214126 1.30667 0.364543 1.48084Z"
          fill="var(--main-blue-dark)"
        />
      </svg> -->
    </div>
  </div>
</div>

<div class="movanoMiddleBar fixed-middle-bar">
  <div class="movanoMiddleBar_First">
    <app-calendar
      #list_calendar
      (initGetInfo)="emitGetInfo($event)"
      [slctdDateOpt]="selectedDate"
      [timeZones]="timeZones"
      [ngStyle]="{ 'pointer-events': valueSearch ? 'none' : 'auto', opacity: valueSearch ? '0.5' : '1' }"
    ></app-calendar>
    <svg
      class="movanoHeader_Tittle_btn"
      style="margin-left: 20px"
      width="max(1.11vw, 16px)"
      height="max(1.11vw, 16px)"
      *ngIf="!searchDisplay"
      (click)="searchDisplay = true"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
        fill="var(--main-blue-dark)"
      />
    </svg>
    <div
      style="margin-right: 20px; margin-left: 20px"
      *ngIf="searchDisplay"
      class="movanoHeader_Tittle_Search scale-up-hor-left"
    >
      <svg
        (click)="search($event, 'customer_uuid', deviceTable)"
        width="max(1.11vw, 16px)"
        height="max(1.11vw, 16px)"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
          fill="var(--main-blue-dark)"
        />
      </svg>
      <span class="p-input-icon-left">
        <input
          class="movanoInputSearch"
          type="text"
          placeholder="Search device"
          #searchInput
          pInputText
          (input)="search($event, 'customer_uuid,device_serial', deviceTable)"
        />
      </span>
      <svg
        width="max(0.833vw, 16px)"
        height="max(0.833vw, 16px)"
        (click)="clearSearch(searchInput, deviceTable); searchDisplay = false; this.isFiltered = false"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE"
        />
      </svg>
    </div>
    <!-- <svg
      (click)="filterDisplay = true"
      style="margin-left: 20px"
      class="movanoHeader_Tittle_btn"
      width="max(0.9vw, 16px)"
      height="max(0.9vw, 16px)"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.364543 1.48084C0.443709 1.58375 4.90871 7.28375 4.90871 7.28375V12.0417C4.90871 12.4771 5.26496 12.8333 5.70829 12.8333H7.29954C7.73496 12.8333 8.09913 12.4771 8.09913 12.0417V7.27584C8.09913 7.27584 12.4454 1.71834 12.6512 1.465C12.857 1.21167 12.8333 0.958338 12.8333 0.958338C12.8333 0.522922 12.477 0.166672 12.0337 0.166672H0.966209C0.483293 0.166672 0.166626 0.546672 0.166626 0.958338C0.166626 1.11667 0.214126 1.30667 0.364543 1.48084Z"
        fill="var(--main-blue-dark)"
      />
    </svg> -->
  </div>

  <div class="movanoMiddleBar_Last">
    <span class="movanoMiddleBar_Last_Amount">{{ devicesTotal }} devices</span>

    <!-- <svg
      style="margin-right: 30px"
      width="max(1.11vw, 16px)"
      height="max(1.11vw, 17px)"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.249999 9.0625L7.09563 9.0625L3.95125 12.2069L4.75 13L9.25 8.5L4.75 4L3.95688 4.79313L7.09563 7.9375L0.249999 7.9375L0.249999 9.0625Z"
        fill="#054280"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.75065 13.3529L3.59709 12.2075L6.49209 9.31248L2.22877e-05 9.31248L2.24298e-05 7.68748L6.49286 7.68748L3.6035 4.79294L4.75002 3.64642L9.60358 8.49998L4.75065 13.3529ZM4.7494 12.647L8.89647 8.49998L4.75002 4.35353L4.31029 4.79326L7.69844 8.18748L0.500022 8.18748L0.500022 8.81248L7.6992 8.81248L4.30545 12.2062L4.7494 12.647Z"
        fill="#054280"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.25 2H2.25V0H15.25V2Z" fill="#054280" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.25 17H2.25V15H15.25V17Z" fill="#054280" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.25 1V17H13.25L13.25 1H15.25Z" fill="#054280" />
    </svg> -->

    <svg
      style="margin-right: 30px; cursor: pointer"
      (click)="exportCSV()"
      width="max(1.11vw, 17px)"
      height="max(1.11vw, 19px)"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.186 1.1897e-05H4.60464L3.00801 0L3 16C3 16 3.71406 16 4.59662 16H13.3953C14.2779 16 15 16 15 16V4.75109L10.186 1.1897e-05ZM13.3953 14.393H4.60464V1.53712H9.38372V5.55458H13.3953V14.393Z"
        fill="#054280"
      />
      <path d="M0 10H17V19H0V10Z" fill="#054280" />
      <path d="M12.8696 17L11.1546 12.1H12.1276L13.3316 15.775L14.5426 12.1H15.5156L13.7936 17H12.8696Z" fill="white" />
      <path
        d="M8.99207 17.105C8.57207 17.105 8.18474 17.014 7.83007 16.832C7.48007 16.6453 7.2024 16.3303 6.99707 15.887L7.76007 15.467C7.8954 15.7097 8.05874 15.8987 8.25007 16.034C8.4414 16.1693 8.7004 16.237 9.02707 16.237C9.34907 16.237 9.56374 16.1833 9.67107 16.076C9.7784 15.964 9.83207 15.817 9.83207 15.635C9.83207 15.5183 9.80407 15.418 9.74807 15.334C9.69674 15.25 9.58007 15.1707 9.39807 15.096C9.22074 15.0167 8.9454 14.9327 8.57207 14.844C8.1194 14.7367 7.78574 14.564 7.57107 14.326C7.3564 14.088 7.24907 13.7917 7.24907 13.437C7.24907 13.143 7.31907 12.8863 7.45907 12.667C7.59907 12.4477 7.79507 12.2773 8.04707 12.156C8.30374 12.03 8.60474 11.967 8.95007 11.967C9.2674 11.967 9.5474 12.016 9.79007 12.114C10.0327 12.212 10.2381 12.3403 10.4061 12.499C10.5787 12.6577 10.7141 12.8303 10.8121 13.017L10.0911 13.556C10.0211 13.444 9.93474 13.3343 9.83207 13.227C9.73407 13.115 9.61274 13.0217 9.46807 12.947C9.3234 12.8723 9.14607 12.835 8.93607 12.835C8.71674 12.835 8.53707 12.8817 8.39707 12.975C8.26174 13.0637 8.19407 13.2037 8.19407 13.395C8.19407 13.4697 8.21274 13.5467 8.25007 13.626C8.29207 13.7007 8.3854 13.7753 8.53007 13.85C8.6794 13.9247 8.91507 14.0017 9.23707 14.081C9.78307 14.2117 10.1774 14.4077 10.4201 14.669C10.6674 14.9257 10.7911 15.243 10.7911 15.621C10.7911 16.1017 10.6277 16.4703 10.3011 16.727C9.97907 16.979 9.54274 17.105 8.99207 17.105Z"
        fill="white"
      />
      <path
        d="M4.54096 17.119C4.09762 17.119 3.70096 17.014 3.35096 16.804C3.00096 16.594 2.72562 16.2977 2.52496 15.915C2.32896 15.5277 2.23096 15.0727 2.23096 14.55C2.23096 14.0227 2.32896 13.5677 2.52496 13.185C2.72562 12.7977 3.00096 12.499 3.35096 12.289C3.70096 12.0743 4.09762 11.967 4.54096 11.967C4.95629 11.967 5.32962 12.0603 5.66096 12.247C5.99696 12.4337 6.26529 12.695 6.46596 13.031L5.65396 13.5C5.53729 13.2947 5.38329 13.136 5.19196 13.024C5.00529 12.912 4.78829 12.856 4.54096 12.856C4.26562 12.856 4.02529 12.9283 3.81996 13.073C3.61929 13.213 3.46296 13.409 3.35096 13.661C3.23896 13.913 3.18296 14.2093 3.18296 14.55C3.18296 14.886 3.23896 15.18 3.35096 15.432C3.46296 15.684 3.61929 15.8823 3.81996 16.027C4.02529 16.167 4.26562 16.237 4.54096 16.237C4.78829 16.237 5.00529 16.181 5.19196 16.069C5.38329 15.9523 5.53729 15.7937 5.65396 15.593L6.46596 16.062C6.26529 16.3933 5.99696 16.6523 5.66096 16.839C5.32962 17.0257 4.95629 17.119 4.54096 17.119Z"
        fill="white"
      />
    </svg>

    <!-- <svg
      style="margin-right: 19px"
      width="max(1.11vw, 16px)"
      height="max(1.11vw, 16px)"
      viewBox="0 0 3 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" fill="#054280" />
        <circle cx="1.5" cy="7.5" r="1.5" fill="#054280" />
        <circle cx="1.5" cy="13.5" r="1.5" fill="#054280" />
      </g>
    </svg> -->
  </div>
</div>

<div class="movanoDevices" [ngClass]="{ movanoDark: darkMode }">
  <div class="movanoTableBox" [ngStyle]="{ display: !loadingDevicesList ? 'block' : 'none' }">
    <p-table
      #deviceTable
      class="movano-Table"
      [globalFilterFields]="[
        'device_name',
        'customer_uuid',
        '',
        'device_type',
        'device_serial',
        'firmware',
        'hardware',
        'status',
        'last_charge',
        'last_sync',
        'tickets'
      ]"
      [value]="filteredDevices"
      [paginator]="true"
      [rows]="rows"
      [first]="pageSelected"
      [tableStyle]="{ 'min-width': '50rem' }"
      (onFilter)="onFilter($event)"
      [scrollable]="true"
      scrollHeight="75vh"
    >
      <ng-template pTemplate="header">
        <tr [ngClass]="'movanoTable_Header'">
          <th (click)="sort('deviceName')">{{ LITERALS.DEVICES.TABLE_NAME }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="deviceName-icon"
              d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
              fill="#C4D9EE"
            />
          </svg>
        </th>
          <th (click)="sort('customerUuid')">{{ LITERALS.DEVICES.TABLE_USERID }} <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="customerUuid-icon"
              d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
              fill="#C4D9EE"
            />
          </svg></th>
          <th></th>
          <th>{{ LITERALS.DEVICES.TABLE_TYPE }}</th>
          <th (click)="sort('deviceSerial')">{{ LITERALS.DEVICES.TABLE_SN }} <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="deviceSerial-icon"
              d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
              fill="#C4D9EE"
            />
          </svg></th>
          <th (click)="sort('firmware')">{{ LITERALS.DEVICES.TABLE_FIRMWARE }} <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="firmware-icon"
              d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
              fill="#C4D9EE"
            />
          </svg></th>
          <th (click)="sort('hardware')">{{ LITERALS.DEVICES.TABLE_HARDWARE }} <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="hardware-icon"
              d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
              fill="#C4D9EE"
            />
          </svg></th>
          <th (click)="sort('status')">{{ LITERALS.DEVICES.TABLE_STATUS }} <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="status-icon"
              d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
              fill="#C4D9EE"
            />
          </svg></th>
          <th (click)="sort('lastCharge')">{{ LITERALS.DEVICES.TABLE_LAST_CHARGE }} <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="lastCharge-icon"
              d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
              fill="#C4D9EE"
            />
          </svg></th>
          <th (click)="sort('lastSync')">{{ LITERALS.DEVICES.TABLE_LAST_SYNC }} <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="lastSync-icon"
              d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
              fill="#C4D9EE"
            />
          </svg></th>
          <th>{{ LITERALS.DEVICES.TABLE_TICKET }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-i="rowIndex">
        <tr
          [ngStyle]="{
            background: darkMode ? (i % 2 == 0 ? '#05294d' : '#05305B') : i % 2 == 0 ? '#f3f6f9' : 'white'
          }"
          [ngClass]="'movanoTable_Obj'"
        >
          <td>{{ product.deviceName }}</td>
          <td>{{ product.customerUuid }}</td>
          <td (click)="openUserPanel(product.customerUuid)">
            <svg style="cursor: pointer" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Group 721">
                <path id="Vector 23" d="M1 7V12" stroke="#0B84FF" stroke-width="2" stroke-linecap="round" />
                <path id="Vector 24" d="M6 4L6 12" stroke="#0B84FF" stroke-width="2" stroke-linecap="round" />
                <path id="Vector 25" d="M11 1L11 12" stroke="#0B84FF" stroke-width="2" stroke-linecap="round" />
              </g>
            </svg>
          </td>

          <td>{{ product.deviceType }}</td>
          <td>{{ product.deviceSerial }}</td>
          <td>{{ product.firmware }}</td>
          <td>{{ product.hardware }}</td>
          <td>
            <div *ngIf="product.status" class="movanoTable_Obj_Badge" [ngClass]="getBadgeColor(product.status)">
              {{ product.status }}
            </div>
          </td>

          <td>{{ product.lastCharge | date : "MM/dd/yy" }}</td>
          <td>{{ product.lastSync | date : "MM/dd/yy" }}</td>
          <td (click)="openTicketsPanel(product.deviceName)">
            <svg
              style="cursor: pointer"
              width="max(1.5vw, 20px)"
              height="max(1.8vw, 22px)"
              viewBox="0 0 15 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.75 8H1.41667C0.6375 8 0.00708333 8.6375 0.00708333 9.41667L0 22.1667L2.83333 19.3333H12.75C13.5292 19.3333 14.1667 18.6958 14.1667 17.9167V9.41667C14.1667 8.6375 13.5292 8 12.75 8Z"
                fill="#E22B2B"
              />
              <path
                d="M6.909 15.146L6.72 10.7H7.953L7.764 15.146H6.909ZM7.332 17.135C7.128 17.135 6.954 17.066 6.81 16.928C6.672 16.784 6.603 16.61 6.603 16.406C6.603 16.202 6.672 16.031 6.81 15.893C6.954 15.749 7.128 15.677 7.332 15.677C7.536 15.677 7.71 15.749 7.854 15.893C7.998 16.031 8.07 16.202 8.07 16.406C8.07 16.61 7.998 16.784 7.854 16.928C7.71 17.066 7.536 17.135 7.332 17.135Z"
                fill="white"
              />
            </svg>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorright" let-state>
        <div class="movanoPaginator">
          <svg (click)="previousPage()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.3333 5.33335L3.21996 5.33335L6.94663 1.60669L5.99996 0.666687L0.666626 6.00002L5.99996 11.3334L6.93996 10.3934L3.21996 6.66669L11.3333 6.66669V5.33335Z"
              [ngStyle]="{ fill: state.page > 0 ? '#0B84FF' : '#AABED3' }"
            />
          </svg>

          <button *ngIf="!isFirstPageInRange()" (click)="selectPage(1)" [ngClass]="{ 'active-page': actualPage === 1 }">
            1
          </button>

          <svg *ngIf="shouldShowLeftEllipsis()" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="8" rx="4" fill="#C4D9EE" />
            <circle cx="9" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="13" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="5" cy="4" r="1" fill="var(--main-blue-dark)" />
          </svg>

          <button
            *ngFor="let page of getDisplayedPages()"
            [ngClass]="{ 'active-page': page === actualPage }"
            (click)="selectPage(page)"
          >
            {{ page }}
          </button>

          <svg *ngIf="shouldShowRightEllipsis()" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="8" rx="4" fill="#C4D9EE" />
            <circle cx="9" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="13" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="5" cy="4" r="1" fill="var(--main-blue-dark)" />
          </svg>

          <button
            *ngIf="!isLastPageInRange()"
            (click)="selectPage(totalPages)"
            [ngClass]="{ 'active-page': actualPage === totalPages }"
          >
            {{ totalPages }}
          </button>

          <svg (click)="nextPage()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.666647 6.66665L8.77998 6.66665L5.05331 10.3933L5.99998 11.3333L11.3333 5.99998L5.99998 0.666646L5.05998 1.60665L8.77998 5.33331L0.666647 5.33331L0.666647 6.66665Z"
              [ngStyle]="{ fill: state.page < state.pageCount - 1 ? '#0B84FF' : '#AABED3' }"
            />
          </svg>
        </div>

        <!-- {{state | json}} -->
      </ng-template>
    </p-table>
    <div id="mobileScroll" class="movanoTableBox_mobileShadow"></div>
    <div id="mobileScroll_Left" class="movanoTableBox_mobileShadow movanoTableBox_mobileShadow_Left"></div>
  </div>
  <div *ngIf="loadingDevicesList" style="position: absolute; top: 50%; left: 50%">
    <p-progressSpinner
      [style]="{ width: '6vw', height: '6vw' }"
      styleClass="{{ darkMode ? 'custom-spinner' : 'custom-spinner-blue' }}"
      strokeWidth="2"
      animationDuration="1.5s"
    >
    </p-progressSpinner>
  </div>
</div>

<!-- <p-sidebar styleClass="menu-left" [modal]="false" [(visible)]="menuDisplay" position="left">
  <app-features-tab (closeHimself)="menuDisplay = false"></app-features-tab>
</p-sidebar>
 -->
<p-sidebar styleClass="menu-filter" [modal]="false" [(visible)]="filterDisplay" position="left">
  <ng-template pTemplate="header">
    <h3>{{ LITERALS.FILTER.TITTLE }}</h3>
  </ng-template>
  <div [ngClass]="{ movanoDark: darkMode }">
    <div class="movanoFilterMenu">
      <div class="movanoFilterMenu_Header">
        <span>{{ LITERALS.FILTER.TITTLE }}</span>

        <a (click)="resetFilter(deviceTable)">{{ LITERALS.FILTER.RESET }}</a>
        <svg
          (click)="filterDisplay = false"
          width="0.833vw"
          height="0.833vw"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
            fill="#C4D9EE"
          />
        </svg>
      </div>
      <div class="movanoFilterMenu_Filter">
        <span class="movanoFilterMenu_Filter_Tittle">{{ LITERALS.FILTER.DEVICE_STATUS }}</span>
        <div class="movanoFilterMenu_Filter_checkBox">
          <mat-checkbox class="movano_checkBox" (change)="onStatusChange('Sold', $event, deviceTable)">
            <div class="movanoTable_Obj_Badge">sold</div>
          </mat-checkbox>
          <mat-checkbox class="movano_checkBox" (change)="onStatusChange('Registered', $event, deviceTable)">
            <div class="movanoTable_Obj_Badge movanoTable_Obj_Badge">registered</div>
          </mat-checkbox>
          <mat-checkbox class="movano_checkBox" (change)="onStatusChange('Active', $event, deviceTable)">
            <div class="movanoTable_Obj_Badge movanoTable_Obj_Badge_green">active</div>
          </mat-checkbox>
          <mat-checkbox class="movano_checkBox" (change)="onStatusChange('Repairing', $event, deviceTable)">
            <div class="movanoTable_Obj_Badge movanoTable_Obj_Badge_orange">repairing</div>
          </mat-checkbox>
          <mat-checkbox class="movano_checkBox" (change)="onStatusChange('Repaired', $event, deviceTable)">
            <div class="movanoTable_Obj_Badge movanoTable_Obj_Badge_orangeDark">repaired</div>
          </mat-checkbox>
          <mat-checkbox class="movano_checkBox" (change)="onStatusChange('Disposal/Returned', $event, deviceTable)">
            <div class="movanoTable_Obj_Badge movanoTable_Obj_Badge_gray">disposal/returned</div>
          </mat-checkbox>
          <mat-checkbox class="movano_checkBox" (change)="onStatusChange('Broke device', $event, deviceTable)">
            <div class="movanoTable_Obj_Badge movanoTable_Obj_Badge_grayMix">broke device</div>
          </mat-checkbox>
          <mat-checkbox class="movano_checkBox" (change)="onStatusChange('Problem detected', $event, deviceTable)">
            <div class="movanoTable_Obj_Badge movanoTable_Obj_Badge_red">problem detected</div>
          </mat-checkbox>
        </div>
      </div>
      <div class="movanoFilterMenu_Filter">
        <span class="movanoFilterMenu_Filter_Tittle">{{ LITERALS.FILTER.DEVICE_TYPE }}</span>
        <div class="movanoFilterMenu_Filter_checkBox">
          <mat-checkbox class="movano_checkBox device_firmware_box">Device type 1</mat-checkbox>
          <mat-checkbox class="movano_checkBox device_firmware_box">Device type 2</mat-checkbox>
          <mat-checkbox class="movano_checkBox device_firmware_box">Device type 3</mat-checkbox>
          <mat-checkbox class="movano_checkBox device_firmware_box">Device type 4</mat-checkbox>
        </div>
      </div>
      <div class="movanoFilterMenu_Filter">
        <span class="movanoFilterMenu_Filter_Tittle">{{ LITERALS.FILTER.DEVICE_FIRMWARE }}</span>
        <!-- Container for the first four firmware checkboxes -->
        <div class="movanoFilterMenu_Filter_checkBox">
          <mat-checkbox
            *ngFor="let firmware of firstFourFirmwares; let i = index"
            class="movano_checkBox device_firmware_box"
            [(ngModel)]="firmwaresCheckboxs[i]"
            (click)="toggleFirmwareFilter(firmware, table)"
          >
            <span>{{ firmware }}</span>
          </mat-checkbox>
        </div>
        <!-- Container for the remaining firmware checkboxes, initially hidden -->
        <div *ngIf="showMore" class="movanoFilterMenu_Filter_checkBox">
          <mat-checkbox
            *ngFor="let firmware of remainingFirmwares; let i = index"
            class="movano_checkBox device_firmware_box"
            [(ngModel)]="firmwaresCheckboxs[firstFourFirmwares.length + i]"
            (click)="toggleFirmwareFilter(firmware, table)"
          >
            <span>{{ firmware }}</span>
          </mat-checkbox>
        </div>
        <!-- "View More" link -->
        <a style="padding: 14px" *ngIf="remainingFirmwares.length > 0" (click)="toggleShowMore()">
          {{ showMore ? "View Less" : "View More" }}
        </a>
      </div>

      <div class="movanoFilterMenu_Filter">
        <span class="movanoFilterMenu_Filter_Tittle">{{ LITERALS.FILTER.DEVICE_LAST_CHARGE }}</span>
        <div class="movanoCalendar">
          <mat-icon [ngStyle]="{ color: false ? '#FFFFFF' : '' }">calendar_today</mat-icon>
          <p-calendar
            [maxDate]="today!"
            selectionMode="range"
            placeholder="Since      |   To"
            (onSelect)="applyFilter()"
            dateFormat="d MM ‘y"
            rangeSeparator="|"
            [(ngModel)]="filter_lastCharge"
          >
            <ng-template pTemplate="date" let-date>
              <div
                *ngIf="filter_lastCharge[0]"
                [ngClass]="{ selectedDate: date.day === filter_lastCharge[0].getDate() }"
              ></div>
              <div
                *ngIf="filter_lastCharge[1]"
                [ngClass]="{
                  interDate: date.day != filter_lastCharge[0].getDate() && date.day != filter_lastCharge[1].getDate()
                }"
              ></div>
              <div
                *ngIf="filter_lastCharge[1]"
                [ngClass]="{ selectedDate: date.day === filter_lastCharge[1].getDate() }"
              ></div>
              {{ date.day }}
            </ng-template>
          </p-calendar>
          <svg
            style="transform: rotate(0deg); position: absolute; bottom: 42%; right: 0.84vw"
            width="0.555vw"
            height="0.4166vw"
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
              fill="#284767"
            />
          </svg>
        </div>
      </div>
      <div class="movanoFilterMenu_Filter">
        <span class="movanoFilterMenu_Filter_Tittle">{{ LITERALS.FILTER.DEVICE_LAST_SYNC }}</span>
        <div class="movanoCalendar">
          <mat-icon [ngStyle]="{ color: false ? '#FFFFFF' : '' }">calendar_today</mat-icon>
          <p-calendar
            [maxDate]="today!"
            selectionMode="range"
            placeholder="Since      |   To"
            (onSelect)="applyFilter()"
            dateFormat="d MM ‘y"
            rangeSeparator="|"
            [(ngModel)]="filter_lastSync"
          >
            <ng-template pTemplate="date" let-date>
              <div
                *ngIf="this.filter_lastSync[0]"
                [ngClass]="{ selectedDate: date.day === filter_lastSync[0].getDate() }"
              ></div>
              <div
                *ngIf="this.filter_lastSync[1]"
                [ngClass]="{
                  interDate: date.day != filter_lastSync[0].getDate() && date.day != filter_lastSync[1].getDate()
                }"
              ></div>
              <div
                *ngIf="this.filter_lastSync[1]"
                [ngClass]="{ selectedDate: date.day === filter_lastSync[1].getDate() }"
              ></div>
              {{ date.day }}
            </ng-template>
          </p-calendar>
          <svg
            style="transform: rotate(0deg); position: absolute; bottom: 42%; right: 0.84vw"
            width="0.555vw"
            height="0.4166vw"
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
              fill="#284767"
            />
          </svg>
        </div>
      </div>
      <div class="movanoFilterMenu_Filter">
        <span class="movanoFilterMenu_Filter_Tittle">Devices with</span>
        <div class="movanoFilterMenu_Filter_checkBox">
          <mat-checkbox class="movano_checkBox" [(ngModel)]="tickets" (click)="applyFilter()">
            <div>Tickets</div>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>

<p-sidebar styleClass="user-menu" [(visible)]="userDisplay" position="right">
  <app-user-menu (closeHimself)="userDisplay = false"></app-user-menu>
</p-sidebar>
