import { Injectable } from "@angular/core";
import { Amplify, Auth } from "aws-amplify";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as LITERALS from "./shared/movano-literals";
import awsconfig from "../aws-exports";
import { BehaviorSubject, lastValueFrom } from "rxjs";

import {
  deviceTable,
  incidentCarrousel,
  salesProductionCarrousel,
  salesSyncCarrousel,
  totalInfoPerMonth,
  User,
  healthMetrics_Hour,
  healthMetrics_Day,
  mixpanelUserProfile,
  sleepData_Hour,
  sleepData_Day,
  ntfInsightAppTable,
  ntfListTable,
  ntfInsightJobTable,
  ntfInsightJobRunsTable,
  barSideChart,
  SpotCheck_Hourly,
  SpotCheck_Daily,
  ticketsTable,
  workoutUsageTable,
  mapTable,
  mapTableDevices,
  AccountTypeResponse,
  organizationPatchData,
  organizationPostData,
  adminUsersPostData,
} from "./shared/movano-interfaces";
import { Observable } from "rxjs";
import { dateToYYYYMMDD } from "./shared/utils";
import { AuthService } from "./login/auth.service";
import { cdkEnvironment } from "src/environments/environment";
Amplify.configure(awsconfig);
Amplify.Logger.LOG_LEVEL = "DEBUG";

@Injectable({
  providedIn: "root",
})
export class MovanoService {
  currentSortedColumn!: string;
  sortOrder: { [key: string]: "none" | "asc" | "desc" } = {};
  private _selectedTab: BehaviorSubject<string> = new BehaviorSubject<string>("insight-application");
  public currentTab = this._selectedTab.asObservable();
  protected selectedStartDate: Date | null = null;
  protected selectedEndDate: Date | null = null;
  private selectedTimeFrame: string | undefined;
  private headers: HttpHeaders;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.headers = new HttpHeaders({});

    Auth.currentSession()
      .then((session) => {
        const idToken = session.getIdToken().getJwtToken();

        this.headers = new HttpHeaders({
          "x-api-key": cdkEnvironment.apiKey,
          Authorization: "Bearer " + idToken,
        });
      })
      .catch((err) => console.log(err));
  }

  //Log an user by the usern name (we user the email as user name) and password
  public async Login(user: User): Promise<any> {
    const userLogged = await Auth.signIn(user.email, user.password);

    this.headers = new HttpHeaders({
      "x-api-key": cdkEnvironment.apiKey,
      Authorization: "Bearer " + userLogged.signInUserSession.idToken.jwtToken,
    });

    localStorage.setItem("userIsLogged", "true");
    return userLogged;
  }

  public completeSign(user: any, pass: string): Promise<any> {
    return Auth.completeNewPassword(user, pass);
  }

  public newUser(user: User): Promise<any> {
    const params = {
      username: user.email,
      password: "EvieRing23",
      attributes: {
        email: user.email,
      },
    };
    return Auth.signUp(params);
  }

  public editUser(user: User): Promise<any> {
    return Auth.updateUserAttributes(user.email, { email: "pmartin@naevatec.com" });
  }

  //Log out the local user
  public LogOut(): Promise<any> {
    localStorage.setItem("userIsLogged", "");

    return Auth.signOut({ global: true });
  }

  //get all the info of the actual logged user
  public getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }

  private async decodeToken(): Promise<any> {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();

      const body = { token: idToken };

      const response = await lastValueFrom(
        this.http.post<AccountTypeResponse>(
          LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
            LITERALS.MOVANO_BACK.CDK_ENV.stage +
            LITERALS.MOVANO_BACK.CDK_ENV.V3 +
            "auth/decode",
          body,
          { headers: this.headers },
        ),
      );

      return response.tokenVerified.payload;
    } catch (error) {
      console.error("Error in API call: ", error);
      throw error;
    }
  }

  public async getAccountType(): Promise<string> {
    const payload = await this.decodeToken();
    return payload["custom:accountType"];
  }

  public async getAccountName(): Promise<string> {
    const payload = await this.decodeToken();
    return payload["name"];
  }

  public async getAccountEmail(): Promise<string> {
    const payload = await this.decodeToken();
    return payload["email"];
  }

  //send a email to recover the password
  public forgotPassword(user: User): Promise<any> {
    return Auth.forgotPassword(user.email);
  }

  //Send cognito the verification code and the new password for change it
  public forgotPasswordSubmit(user: User, new_password: string): Promise<any> {
    return Auth.forgotPasswordSubmit(user.email, user.code, new_password);
  }

  //If the user lost his registration code, we can send him another
  public newCode(user: User) {
    return Auth.resendSignUp(user.email);
  }

  /*
  Return the amount of:
   units produced, rings produced, units sold, rings sold, customers and incidents
   from this month and the last
  */
  public getTotalLastMonthInfo(): Observable<totalInfoPerMonth> {
    return this.http.get<totalInfoPerMonth>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        "dashboard?timeFrame=lastMonth",
      { headers: this.headers },
    );
  }

  public getTotalSyncsLastMonth(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        "devices/stats?timeFrame=lastMonth&syncs=value,bestDayByGenders,bestDayAgeRanges",
      { headers: this.headers },
    );
  }

  /*
  Return the amount of:
  sync data, customer information (ages, sex, distribution by ages and sex, interests, ...),
  app versions, firmware versions independent of the month
   */
  // public getFooterInfo(): Observable<footerInfo> {
  //   return this.http.get<footerInfo>(LITERALS.MOVANO_BACK.BACK_URL + LITERALS.MOVANO_BACK.FOOTER_INFO);
  // }

  /**
  Return info amout ever user in movano
 */
  /*   public getUserTable(month: number = 1, amount: number): Observable<any> {
      return this.http.get<any>(LITERALS.MOVANO_BACK.BACK_URL + LITERALS.MOVANO_BACK.USER_TABLE);
    }
   */

  public getUserTable(
    month: number = 1,
    amount: number,
    usersDateUntil?: Date,
    usersTimeFrameUntil?: string,
  ): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.USER_TABLE +
        `?&usersDateUntil=${dateToYYYYMMDD(usersDateUntil)}&usersTimeFrameUntil=${usersTimeFrameUntil}`,
      { headers: this.headers },
    );
  }
  /**
  Return info amout ever device in movano
 */
  public getDeviceTable(month: number = 1, amount: number, _date?: Date, timeFrame?: string): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.DEVICE_TABLE +
        `?&date=${dateToYYYYMMDD(_date)}&timeFrame=${timeFrame}`,
      { headers: this.headers },
    );
  }

  /**
  Return info amout ever Insight Application Notification
 */

  public getNtfnInsightApp(): Observable<ntfInsightAppTable> {
    return this.http.get<ntfInsightAppTable>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.INSIGHT_APP,
      { headers: this.headers },
    );
  }

  public getWrkUsageData(
    uuid: string,
    timeZoneMode: string,
    utcOffset: number,
    timeFrame?: string,
    _dateStart?: Date,
    _dateEnd?: Date,
  ): Observable<workoutUsageTable[]> {
    return this.http.get<workoutUsageTable[]>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.WORKOUT_USAGE +
        `${uuid}?&timeFrame=${timeFrame}&timeZoneMode=${timeZoneMode}&utcOffset=${utcOffset}&dateStart=${dateToYYYYMMDD(
          _dateStart,
        )}&dateEnd=${dateToYYYYMMDD(_dateEnd)}`,
      { headers: this.headers },
    );
  }

  /**
  Return info amout ever Insight Application Notification
 */
  public getNtfnInsightJob(_dateStart?: Date, _dateEnd?: Date, timeFrame?: string): Observable<ntfInsightJobTable[]> {
    return this.http.get<ntfInsightJobTable[]>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.JOB_EXECUTION +
        `?timeFrame=${timeFrame}&dateStart=${dateToYYYYMMDD(_dateStart)}&dateEnd=${dateToYYYYMMDD(_dateEnd)}`,
      { headers: this.headers },
    );
  }

  /**
  Return info amout ever Insight Application Notification
 */
  /*  public getNtfnInsightJobRuns(ntf: string): Observable<ntfInsightJobRunsTable[]> {
     return this.http.get<ntfInsightJobRunsTable[]>(LITERALS.MOVANO_BACK.NEW_BACK_URL + LITERALS.MOVANO_BACK.JOB_EXECUTION);
   } */

  public getNtfnInsightJobRuns(
    insightAppName: string,
    timeFrame: string,
    _dateStart?: Date,
    _dateEnd?: Date,
  ): Observable<ntfInsightJobRunsTable[]> {
    const url = `${
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
      LITERALS.MOVANO_BACK.CDK_ENV.stage +
      LITERALS.MOVANO_BACK.CDK_ENV.V2 +
      LITERALS.MOVANO_BACK.CDK_ENV.MMP
    }${LITERALS.MOVANO_BACK.JOB_EXECUTION}?insightAppName=${insightAppName}&dateStart=${dateToYYYYMMDD(
      _dateStart,
    )}&dateEnd=${dateToYYYYMMDD(_dateEnd)}&timeFrame=${timeFrame}`;
    return this.http.get<ntfInsightJobRunsTable[]>(url, { headers: this.headers });
  }

  /**
  Return info amout ever Insight Application Notification
 */

  /** Return Notification List of a specific Job Id**/

  public getNtfnListByJobId(
    jobId: string,
    _dateStart?: Date,
    _dateEnd?: Date,
    timeFrame?: string,
  ): Observable<ntfListTable[]> {
    return this.http.get<ntfListTable[]>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.NOTIFICATION_LIST +
        `?&jobId=${jobId}&dateStart=${dateToYYYYMMDD(_dateStart)}&dateEnd=${dateToYYYYMMDD(
          _dateEnd,
        )}&timeFrame=${timeFrame}`,
      { headers: this.headers },
    );
  }

  setSelectedDates(startDate: Date, endDate: Date) {
    this.selectedStartDate = startDate;
    this.selectedEndDate = endDate;
  }

  getSelectedStartDate(): Date | null {
    return this.selectedStartDate;
  }

  getSelectedEndDate(): Date | null {
    return this.selectedEndDate;
  }

  setTimeFrame(timeFrame: string | undefined) {
    this.selectedTimeFrame = timeFrame;
  }

  getTimeFrame(): string | undefined {
    return this.selectedTimeFrame;
  }

  getNtfnListByName(
    insightAppName: string,
    _dateStart?: Date,
    _dateEnd?: Date,
    timeFrame?: string,
  ): Observable<ntfListTable[]> {
    return this.http.get<ntfListTable[]>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.NOTIFICATION_LIST +
        `?insightAppName=${insightAppName}&dateStart=${dateToYYYYMMDD(_dateStart)}&dateEnd=${dateToYYYYMMDD(
          _dateEnd,
        )}&timeFrame=${timeFrame}`,
      { headers: this.headers },
    );
  }

  // getInsightJobByName(name: string, _dateStart?: Date, _dateEnd?: Date,): Observable<ntfInsightJobTable[]> {
  //   return this.http.get<ntfInsightJobTable[]>(LITERALS.MOVANO_BACK.NEW_BACK_URL + LITERALS.MOVANO_BACK.JOB_EXECUTION + `?datestart=${dateToYYYYMMDD(_dateStart)}&dateend=${dateToYYYYMMDD(_dateEnd)}&insightAppName=${name}`, { headers: this.headers });
  // }

  getNtfnListByUser(uuid: string, _dateStart?: Date, _dateEnd?: Date, timeFrame?: string): Observable<ntfListTable[]> {
    return this.http.get<ntfListTable[]>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.NOTIFICATION_LIST +
        `?&uuid=${uuid}&timeFrame=${timeFrame}&dateStart=${dateToYYYYMMDD(_dateStart)}&dateEnd=${dateToYYYYMMDD(
          _dateEnd,
        )}`,
      { headers: this.headers },
    );
  }
  getNtfnAllByUser(uuid: string): Observable<ntfListTable[]> {
    return this.http.get<ntfListTable[]>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.NOTIFICATION_LIST +
        `?&uuid=${uuid}`,
      { headers: this.headers },
    );
  }

  getNtfnList(_dateStart?: Date, _dateEnd?: Date, timeFrame?: string): Observable<ntfListTable[]> {
    return this.http.get<ntfListTable[]>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.NOTIFICATION_LIST +
        `?&timeFrame=${timeFrame}&dateStart=${dateToYYYYMMDD(_dateStart)}&dateEnd=${dateToYYYYMMDD(_dateEnd)}`,
      { headers: this.headers },
    );
  }

  /*
  Return info amout ever device per user in movano
  */
  /* public getUserDevices(_userId: string): Observable<any> {
    return this.http.get<deviceTable[]>(LITERALS.MOVANO_BACK.BACK_URL + LITERALS.MOVANO_BACK.USER_DEVICES + `${_userId}`);
  }
 */

  public getUserDevices(userUuid: string): Observable<any> {
    return this.http.get<deviceTable[]>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.DEVICE_TABLE +
        `?userUuid=${userUuid}`,
      { headers: this.headers },
    );
  }

  /*   return this.http.get<any>(LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL + LITERALS.MOVANO_BACK.CDK_ENV.stage + LITERALS.MOVANO_BACK.CDK_ENV.V2 + LITERALS.MOVANO_BACK.CDK_ENV.MMP  + LITERALS.MOVANO_BACK.DEVICE_TABLE);
  } */

  public getTicketsTable(): Observable<ticketsTable> {
    return this.http.get<ticketsTable>("../assets/mock-files/device_tickets.json");
  }

  public getTicketsTableInfo(): Observable<ticketsTable> {
    return this.http.get<ticketsTable>("../assets/mock-files/device_info.json");
  }

  public getMapList(): Observable<mapTable> {
    return this.http.get<mapTable>("../assets/mock-files/map.json");
  }

  public getMapDevicesList(): Observable<mapTableDevices> {
    return this.http.get<mapTableDevices>("../assets/mock-files/map_devices.json");
  }

  /**
   * Retrieves device measures for a specific date in line format.
   * @param _deviceId The ID of the device.
   * @param _userId The ID of the user.
   * @param _date The date for which the device measures are requested.
   * @param _utc Determines whether the date should be considered in local time or UTC.
   * @returns An Observable of type deviceMeasures_Line containing the device measures for the specified date.
   */
  public getHealthMetrics_Hour(_userId: string, _date: Date, utcMode?: boolean): Observable<healthMetrics_Hour> {
    // Get the user's time zone
    //const tz = (!_utc) ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC';

    return this.http.get<healthMetrics_Hour>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.DEVICE_MEASURE_LINE +
        `${_userId}?date=${dateToYYYYMMDD(_date)}${utcMode === true ? "&timeZoneMode=utc" : ""}`,
      { headers: this.headers },
    );
  }

  public getHealthMetrics_Day(
    _userId: string,
    utcMode?: boolean,
    dateStart?: Date,
    dateEnd?: Date,
    timeFrame?: string,
    dateOffset?: number,
  ): Observable<healthMetrics_Day> {
    return this.http.get<healthMetrics_Day>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.DEVICE_MEASURE_RANGE +
        `${_userId}?dateStart=${dateToYYYYMMDD(dateStart)}&dateEnd=${dateToYYYYMMDD(dateEnd)}${
          utcMode === true ? "&timeZoneMode=utc" : ""
        }&timeFrame=${timeFrame}&dateOffset=${dateOffset}`,
      { headers: this.headers },
    );
  }

  /**
   * Retrieves device measures for a specific range of time.
   * @param _deviceId The ID of the device.
   * @param _userId The ID of the user.
   * @param _quanty The quantity of measures to retrieve.
   * @param _period The period of time for which the measures are requested.
   * @param _utc Determines whether the date should be considered in local time or UTC.
   * @returns An Observable of type deviceMeasures_Range containing the device measures for the specified range.
   */

  public sortTable(ntfLists: any[], column: string) {
    if (this.currentSortedColumn !== column) {
      this.sortOrder[this.currentSortedColumn] = "none";
    }
    this.currentSortedColumn = column;

    if (this.sortOrder[column] === "asc" || this.sortOrder[column] === "none") {
      this.sortOrder[column] = "desc";
      ntfLists.sort((a: any, b: any) => (a[column] > b[column] ? -1 : 1));
    } else {
      this.sortOrder[column] = "asc";
      ntfLists.sort((a: any, b: any) => (a[column] < b[column] ? -1 : 1));
    }
  }

  public updateIcon(column: string) {
    let fill = "";
    let transform = "";

    if (this.sortOrder[column] === "asc") {
      fill = "#054280";
      transform = "rotate(180deg)";
    } else if (this.sortOrder[column] === "desc") {
      fill = "#054280";
      transform = "rotate(0deg)";
    } else if (this.sortOrder[column] === "none") {
      fill = "#C4D9EE";
      transform = "none";
    }
    return { fill, transform };
  }

  changeTab(tab: string) {
    this._selectedTab.next(tab);
  }

  // public getWaveFormData(_userId: string, _block: number, _date: Date, ) : Observable<waformData_Data>{
  //   return this.http.get<waformData_Data>(LITERALS.MOVANO_BACK.BACK_URL + LITERALS.MOVANO_BACK.WAVEFORM + `${_userId}?block=${_block}&date=${dateToYYYYMMDD(_date)}`);
  // }

  // public getWaveFormData(_userId: string, _block: number, _status: 'RETRIEVE' | 'REQUEST', _date: Date, _tz?: string): Observable<any> {
  //   return this.http.get<any>(`${LITERALS.MOVANO_BACK.BACK_URL}${LITERALS.MOVANO_BACK.WAVEFORM}${_userId}?block=${_block}&status=${_status}&date=${dateToYYYYMMDD(_date)}&tz=${_tz}`, { headers: this.headers });
  // }

  public getSleepDataPerHour(
    _userId: string,
    _date: Date,
    utcMode?: boolean,
    dataOffset?: number,
  ): Observable<sleepData_Hour> {
    return this.http.get<sleepData_Hour>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.SLEEP_DATA_HOUR +
        `${_userId}?date=${dateToYYYYMMDD(_date)}${
          utcMode === true ? "&timeZoneMode=utc" : ""
        }&dateOffset=${dataOffset}`,
      { headers: this.headers },
    );
  }

  public getSleepDataPerDay(
    _userId: string,
    dateStart?: Date,
    dateEnd?: Date,
    timeFrame?: string,
    utcMode?: boolean,
    dataOffset?: number,
  ): Observable<sleepData_Day> {
    return this.http.get<sleepData_Day>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.SLEEP_DATA_DAY +
        `${_userId}?dateStart=${dateToYYYYMMDD(dateStart)}&dateEnd=${dateToYYYYMMDD(dateEnd)}&timeFrame=${timeFrame}${
          utcMode === true ? "&timeZoneMode=utc" : ""
        }&dateOffset=${dataOffset}`,
      { headers: this.headers },
    );
  }

  /**
   * Retrieves device measures for a specific date in line format.
   * @param _deviceId The ID of the device.
   * @param _userId The ID of the user.
   * @param _date The date for which the device measures are requested.
   * @param _utc Determines whether the date should be considered in local time or UTC.
   * @returns An Observable of type deviceMeasures_Line containing the device measures for the specified date.
   */

  public getSpotCheckHour(
    _userId: string,
    _date: Date,
    _timeZone?: string,
    dataOffset?: number,
  ): Observable<SpotCheck_Hourly> {
    // Get the user's time zone
    //const tz = (!_utc) ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC';

    if (_timeZone)
      return this.http.get<SpotCheck_Hourly>(
        LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
          LITERALS.MOVANO_BACK.CDK_ENV.stage +
          LITERALS.MOVANO_BACK.CDK_ENV.V2 +
          LITERALS.MOVANO_BACK.CDK_ENV.MMP +
          LITERALS.MOVANO_BACK.SPOT_HOUR +
          `${_userId}?date=${dateToYYYYMMDD(_date)}&timeZoneMode=${_timeZone}&dateOffset=${dataOffset}`,
        { headers: this.headers },
      );
    else
      return this.http.get<SpotCheck_Hourly>(
        LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
          LITERALS.MOVANO_BACK.CDK_ENV.stage +
          LITERALS.MOVANO_BACK.CDK_ENV.V2 +
          LITERALS.MOVANO_BACK.CDK_ENV.MMP +
          LITERALS.MOVANO_BACK.SPOT_HOUR +
          `${_userId}?date=${dateToYYYYMMDD(_date)}&dateOffset=${dataOffset}`,
        { headers: this.headers },
      );
  }

  public getSpotCheckDay(
    _userId: string,
    _timeZone?: string,
    dateStart?: Date,
    dateEnd?: Date,
    timeFrame?: string,
    dataOffset?: number,
  ): Observable<SpotCheck_Daily> {
    return this.http.get<SpotCheck_Daily>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        LITERALS.MOVANO_BACK.SPOT_DAY +
        `${_userId}?dateStart=${dateToYYYYMMDD(dateStart)}&dateEnd=${dateToYYYYMMDD(
          dateEnd,
        )}&timeZoneMode=${_timeZone}&timeFrame=${timeFrame}&dateOffset=${dataOffset}`,
      { headers: this.headers },
    );
  }

  public getBarSideData(_dateStart: Date, _dateEnd: Date): Observable<barSideChart> {
    return this.http.get<barSideChart>(
      `../assets/mock-files/barSideChart_mock.json?datestart=${dateToYYYYMMDD(_dateStart)}&dateend=${dateToYYYYMMDD(
        _dateEnd,
      )}`,
      { headers: this.headers },
    );
  }

  public getBarSideData_Mock_Month(_dateStart: Date, _dateEnd: Date): Observable<barSideChart> {
    return this.http.get<barSideChart>("../assets/mock-files/barSideChart_mock.json");
  }
  public getBarSideData_Mock_Week(_dateStart: Date, _dateEnd: Date): Observable<barSideChart> {
    return this.http.get<barSideChart>("../assets/mock-files/barSideChart_mock_week.json");
  }
  public getBarSideData_Mock_Date_Range(_dateStart: Date, _dateEnd: Date): Observable<barSideChart> {
    return this.http.get<barSideChart>("../assets/mock-files/barSideChart_mock_dateRange.json");
  }
  public getBarSideData_Mock_Year(_dateStart: Date, _dateEnd: Date): Observable<barSideChart> {
    return this.http.get<barSideChart>("../assets/mock-files/barSideChart_mock_year.json");
  }

  //STATS
  //Users stats
  public getStatsSummaryUsersToday(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/users?timeFrame=today`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryUsersLastWeek(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/users?timeFrame=lastWeek`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryUsersLastMonth(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/users?timeFrame=lastMonth`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryUsersLast7Days(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/users?timeFrame=last7Days`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryUsersYearToDate(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/users?timeFrame=actualYear`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryUsersCustomDay(dateStart: string, dateEnd: string): Observable<any> {
    // Construir la URL con las fechas proporcionadas
    const url = `${
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
      LITERALS.MOVANO_BACK.CDK_ENV.stage +
      LITERALS.MOVANO_BACK.CDK_ENV.V2 +
      LITERALS.MOVANO_BACK.CDK_ENV.MMP
    }stats/users?dateStart=${dateStart}&dateEnd=${dateEnd}`;

    // Hacer la solicitud HTTP con la URL construida y los headers necesarios
    return this.http.get<any>(url, { headers: this.headers });
  }

  // Sales stats
  public getStatsSummarySalesToday(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `dashboard?timeFrame=today`,
      { headers: this.headers },
    );
  }

  public getStatsSummarySalesLastWeek(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `dashboard?timeFrame=lastWeek`,
      { headers: this.headers },
    );
  }

  public getStatsSummarySalesLastMonth(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `dashboard?timeFrame=lastMonth`,
      { headers: this.headers },
    );
  }

  public getStatsSummarySalesLast7Days(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `dashboard?timeFrame=last7Days`,
      { headers: this.headers },
    );
  }

  public getStatsSummarySalesYearToDate(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `dashboard?timeFrame=yearToDate`,
      { headers: this.headers },
    );
  }

  public getStatsSummarySalesCustomDay(dateStart: string, dateEnd: string): Observable<any> {
    // Construir la URL con las fechas proporcionadas
    const url = `${
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
      LITERALS.MOVANO_BACK.CDK_ENV.stage +
      LITERALS.MOVANO_BACK.CDK_ENV.V2 +
      LITERALS.MOVANO_BACK.CDK_ENV.MMP
    }dashboard?dateStart=${dateStart}&dateEnd=${dateEnd}`;

    // Hacer la solicitud HTTP con la URL construida y los headers necesarios
    return this.http.get<any>(url, { headers: this.headers });
  }

  //Devices stats
  public getStatsSummaryDevicesToday(): Observable<any> {
    console.log(
      "MOVANO PETICION: " +
        this.http.get<any>(
          LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
            LITERALS.MOVANO_BACK.CDK_ENV.stage +
            LITERALS.MOVANO_BACK.CDK_ENV.V2 +
            LITERALS.MOVANO_BACK.CDK_ENV.MMP +
            `stats/devices?timeFrame=today`,
          { headers: this.headers },
        ),
    );
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/devices?timeFrame=today`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryDevicesLastWeek(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/devices?timeFrame=lastWeek`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryDevicesLastMonth(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/devices?timeFrame=lastMonth`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryDevicesLast7Days(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/devices?timeFrame=last7Days`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryDevicesYearToDate(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/devices?timeFrame=actualYear`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryDevicesCustomDay(dateStart: string, dateEnd: string): Observable<any> {
    const url = `${
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
      LITERALS.MOVANO_BACK.CDK_ENV.stage +
      LITERALS.MOVANO_BACK.CDK_ENV.V2 +
      LITERALS.MOVANO_BACK.CDK_ENV.MMP
    }stats/devices?dateStart=${dateStart}&dateEnd=${dateEnd}`;

    return this.http.get<any>(url, { headers: this.headers });
  }

  //Devices stats donuts
  public getStatsSummaryDevicesTodayDonuts(): Observable<any> {
    console.log(
      "MOVANO PETICION: " +
        this.http.get<any>(
          LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
            LITERALS.MOVANO_BACK.CDK_ENV.stage +
            LITERALS.MOVANO_BACK.CDK_ENV.V2 +
            LITERALS.MOVANO_BACK.CDK_ENV.MMP +
            `devices/stats?timeFrame=today&syncs=byFirmware,byHardware,byAppVersion`,
          { headers: this.headers },
        ),
    );
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `devices/stats?timeFrame=today&syncs=byFirmware,byHardware,byAppVersion`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryDevicesLastWeekDonuts(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `devices/stats?timeFrame=lastWeek&syncs=byFirmware,byHardware,byAppVersion`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryDevicesLastMonthDonuts(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `devices/stats?timeFrame=lastMonth&syncs=byFirmware,byHardware,byAppVersion`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryDevicesLast7DaysDonuts(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `devices/stats?timeFrame=last7Days&syncs=byFirmware,byHardware,byAppVersion`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryDevicesYearToDateDonuts(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `devices/stats?timeFrame=actualYear&syncs=byFirmware,byHardware,byAppVersion`,
      { headers: this.headers },
    );
  }
  public getStatsSummaryDevicesCustomDayDonuts(dateStart: string, dateEnd: string): Observable<any> {
    const url = `${
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL + LITERALS.MOVANO_BACK.CDK_ENV.stage + LITERALS.MOVANO_BACK.CDK_ENV.V3
    }devices/stats?dateStart=${dateStart}&dateEnd=${dateEnd}`;

    return this.http.get<any>(url, { headers: this.headers });
  }

  //User activity stats
  public getUsersActivityToday(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/user-activity?timeFrame=today`,
      { headers: this.headers },
    );
  }
  public getUsersActivityLastWeek(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/user-activity?timeFrame=lastWeek`,
      { headers: this.headers },
    );
  }
  public getUsersActivityLastMonth(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/user-activity?timeFrame=lastMonth`,
      { headers: this.headers },
    );
  }
  public getUsersActivityLast7Days(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/user-activity?timeFrame=last7Days`,
      { headers: this.headers },
    );
  }
  public getUsersActivityYearToDate(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/user-activity?timeFrame=actualYear`,
      { headers: this.headers },
    );
  }
  public getUsersActivityCustomDay(dateStart: string, dateEnd: string): Observable<any> {
    const url = `${
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
      LITERALS.MOVANO_BACK.CDK_ENV.stage +
      LITERALS.MOVANO_BACK.CDK_ENV.V2 +
      LITERALS.MOVANO_BACK.CDK_ENV.MMP
    }stats/user-activity?dateStart=${dateStart}&dateEnd=${dateEnd}`;

    return this.http.get<any>(url, { headers: this.headers });
  }

  //Stast health metric petitions
  public getHealthMetricsStatsToday(): Observable<any> {
    console.log(
      "MOVANO PETICION: " +
        this.http.get<any>(
          LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
            LITERALS.MOVANO_BACK.CDK_ENV.stage +
            LITERALS.MOVANO_BACK.CDK_ENV.V2 +
            LITERALS.MOVANO_BACK.CDK_ENV.MMP +
            `stats/health-metrics?timeFrame=today`,
          { headers: this.headers },
        ),
    );
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/health-metrics?timeFrame=today`,
      { headers: this.headers },
    );
  }
  public getHealthMetricsStatsLastWeek(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/health-metrics?timeFrame=lastWeek`,
      { headers: this.headers },
    );
  }
  public getHealthMetricsStatsLastMonth(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/health-metrics?timeFrame=lastMonth`,
      { headers: this.headers },
    );
  }
  public getHealthMetricsStatsLast7Days(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/health-metrics?timeFrame=last7Days`,
      { headers: this.headers },
    );
  }
  public getHealthMetricsStatsYearToDate(): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `stats/health-metrics?timeFrame=actualYear`,
      { headers: this.headers },
    );
  }
  public getHealthMetricsStatsCustomDay(dateStart: string, dateEnd: string): Observable<any> {
    const url = `${
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
      LITERALS.MOVANO_BACK.CDK_ENV.stage +
      LITERALS.MOVANO_BACK.CDK_ENV.V2 +
      LITERALS.MOVANO_BACK.CDK_ENV.MMP
    }stats/health-metrics?dateStart=${dateStart}&dateEnd=${dateEnd}`;

    return this.http.get<any>(url, { headers: this.headers });
  }

  //USERS
  public getUserUuidByEmail(email: string): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V2 +
        LITERALS.MOVANO_BACK.CDK_ENV.MMP +
        `user/uuid?email=${email}`,
      { headers: this.headers },
    );
  }

  public getUsersBySearch(searchString?: string): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `customers?limit=10000&page=1&customerUuidContains=${searchString}`,
      { headers: this.headers },
    );
  }

  public getCustomerByUUID(uuid: string): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `customers/${uuid}?limit=10&page=1`,
      { headers: this.headers },
    );
  }

  public getVersion(): Observable<any> {
    return this.http.get<any>(LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL + LITERALS.MOVANO_BACK.CDK_ENV.stage + `version`, {
      headers: this.headers,
    });
  }

  public getCustomers(usersPerPage: number, page: number, customersTimeFrameUntil?: string): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `customers?limit=${usersPerPage}&page=${page}&customersTimeFrameUntil=${customersTimeFrameUntil}`,
      { headers: this.headers },
    );
  }

  public getDevices(devicesPerPage: number, page: number, timeFrame?: string): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `devices?limit=${devicesPerPage}&page=${page}&timeFrameUntil=${timeFrame}`,
      { headers: this.headers },
    );
  }

  public getDevicesByCustomerUUIDorDeviceSerial(customerUUIDOrDevice: string): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `devices?limit=1000&page=1&customerUuidOrSerialContains=${customerUUIDOrDevice}`,
      { headers: this.headers },
    );
  }

  public getNotifications(notificationsPerPage: number, page: number, timeFrame?: string): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `notifications?limit=${notificationsPerPage}&page=${page}&timeFrame=${timeFrame}`,
      { headers: this.headers },
    );
  }

  public getNotificationsBySentToCustummer(
    notificationsPerPage: number,
    page: number,
    sentToCustomer?: boolean,
  ): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `notifications?limit=${notificationsPerPage}&page=${page}&sentToCustomer=${sentToCustomer}`,
      { headers: this.headers },
    );
  }

  public getNotificationsByInsightAppName(
    notificationsPerPage: number,
    page: number,
    insightAppName?: string,
  ): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `notifications?limit=${notificationsPerPage}&page=${page}&insightAppName=${insightAppName}`,
      { headers: this.headers },
    );
  }

  public getSalesManagement(
    salesPerPage: number,
    page: number,
    timeFrame: string,
    sortedBy: string,
    sortedMode: string,
    startDate?: string,
    endDate?: string,
  ): Observable<any> {
    if (timeFrame === "custom") {
      return this.http.get<any>(
        LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
          LITERALS.MOVANO_BACK.CDK_ENV.stage +
          LITERALS.MOVANO_BACK.CDK_ENV.V3 +
          `sales?limit=${salesPerPage}&page=${page}&dateStart=${startDate}&dateEnd=${endDate}&sortBy=${sortedBy}&sortMode=${sortedMode}`,
        { headers: this.headers },
      );  
    } else {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales?limit=${salesPerPage}&page=${page}&timeFrame=${timeFrame}&sortBy=${sortedBy}&sortMode=${sortedMode}`,
      { headers: this.headers },
    );
  }
  }

  public getSalesManagementBySoUserName(
    salesPerPage: number,
    page: number,
    soUserName: string,
  ): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales?limit=${salesPerPage}&page=${page}&soUserNameContains=${soUserName}`,
      { headers: this.headers },
    );
  }

  public getSalesManagementBySoNumber(
    salesPerPage: number,
    page: number,
    soNumber: string,
  ): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales?limit=${salesPerPage}&page=${page}&soNumberContains=${soNumber}`,
      { headers: this.headers },
    );
  }

  public getSalesManagementByPoNumber(
    salesPerPage: number,
    page: number,
    poNumber: string,
  ): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales?limit=${salesPerPage}&page=${page}&poNumberContains=${poNumber}`,
      { headers: this.headers },
    );
  }

  public getAdminUsers(
    salesPerPage: number,
    page: number,
    timeFrame: string,
    sortedBy: string,
    sortedMode: string,
  ): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `users?limit=${salesPerPage}&page=${page}&timeFrame=${timeFrame}&sortedBy=${sortedBy}&sortedMode=${sortedMode}`,
      { headers: this.headers },
    );
  }

  public postAdminUser(body: any): Observable<any> {
    return this.http.post<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `users`,
      body,
      { headers: this.headers },
    );
  }

  public patchAdminUser(changedFields: any, id: string): Observable<any> {
    const queryParams = new URLSearchParams(changedFields).toString();
    const url = `${LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL}${LITERALS.MOVANO_BACK.CDK_ENV.stage}${LITERALS.MOVANO_BACK.CDK_ENV.V3}users/${id}?${queryParams}`;

    return this.http.patch<any>(url, null, { headers: this.headers });
  }

  public getOrganizations(
    salesPerPage: number,
    page: number,
    timeFrame: string,
    sortedBy: string,
    sortedMode: string,
  ): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `organizations?limit=${salesPerPage}&page=${page}&timeFrame=${timeFrame}&sortedBy=${sortedBy}&sortedMode=${sortedMode}`,
      { headers: this.headers },
    );
  }

  public postOrganization(body: organizationPostData): Observable<any> {
    return this.http.post<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `organizations`,
      body,
      { headers: this.headers },
    );
  }

  public patchOrganization(body: organizationPatchData, id: string): Observable<any> {
    return this.http.patch<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `organizations/${id}`,
      body,
      { headers: this.headers },
    );
  }

  public getSalesHeaderData(timeFrame: string, dateStart?: string, dateEnd?: string) {
    if (dateStart && dateEnd) {
      return this.http.get<any>(
        LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
          LITERALS.MOVANO_BACK.CDK_ENV.stage +
          LITERALS.MOVANO_BACK.CDK_ENV.V3 +
          `sales/management/stats/sales?dateStart=${dateStart}&dateEnd=${dateEnd}&totals=distinct&newSales=distinct, distinctCompare&colors=bestColor&size=sizerKit, bestSize,`,
        { headers: this.headers },
      );
    }

    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales/management/stats/sales?timeFrame=${timeFrame}&totals=distinct&newSales=distinct, distinctCompare&colors=bestColor&size=sizerKit, bestSize,`,
      { headers: this.headers },
    );
  }

  public getSalesDonutsData(timeFrame: string, dateStart?: string, dateEnd?: string) {
    if (dateStart && dateEnd) {
      return this.http.get<any>(
        LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
          LITERALS.MOVANO_BACK.CDK_ENV.stage +
          LITERALS.MOVANO_BACK.CDK_ENV.V3 +
          `sales/management/stats/sales?dateStart=${dateStart}&dateEnd=${dateEnd}&newSales=salesDistribution&colors=distribution&size=distribution`,
        { headers: this.headers },
      );
    }

    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales/management/stats/sales?timeFrame=${timeFrame}&newSales=salesDistribution&colors=distribution&size=distribution`,
      { headers: this.headers },
    );
  }

  public getSalesByDates(timeFrame: string, dateStart?: string, dateEnd?: string) {
    if (dateStart && dateEnd) {
      return this.http.get<any>(
        LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
          LITERALS.MOVANO_BACK.CDK_ENV.stage +
          LITERALS.MOVANO_BACK.CDK_ENV.V3 +
          `sales/management/stats/sales?dateStart=${dateStart}&dateEnd=${dateEnd}&newSales=byDates&colors=byDates&size=byDates`,
        { headers: this.headers },
      );
    }
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales/management/stats/sales?timeFrame=${timeFrame}&newSales=byDates&colors=byDates,colorList&size=byDates`,
      { headers: this.headers },
    );
  }

  public getSizeList() {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales/management/stats/sales?size=sizeList`,
      { headers: this.headers },
    );
  }

  public getRMAsByDates(timeFrame: string, dateStart?: string, dateEnd?: string) {
    if (dateStart && dateEnd) {
      return this.http.get<any>(
        LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
          LITERALS.MOVANO_BACK.CDK_ENV.stage +
          LITERALS.MOVANO_BACK.CDK_ENV.V3 +
          `sales/rma/stats?dateStart=${dateStart}&dateEnd=${dateEnd}&rma=byDates, sizesByDatesExpanded, colorsByDatesExpanded`,
        { headers: this.headers },
      );
    }
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales/rma/stats?timeFrame=${timeFrame}&rma=byDates, sizesByDatesExpanded, colorsByDatesExpanded`,
      { headers: this.headers },
    );
  }

  public getVips(
    salesPerPage: number,
    page: number,
    timeFrame: string,
    sortedBy: string,
    sortedMode: string,
    startDate?: string,
    endDate?: string,
  ): Observable<any> {
    if (timeFrame === "custom") {
      return this.http.get<any>(
        LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
          LITERALS.MOVANO_BACK.CDK_ENV.stage +
          LITERALS.MOVANO_BACK.CDK_ENV.V3 +
          `sales?limit=${salesPerPage}&page=${page}&dateStart=${startDate}&dateEnd=${endDate}&sortBy=${sortedBy}&sortMode=${sortedMode}&flgVip=true`,
        { headers: this.headers },
      );
    } else {
      return this.http.get<any>(
        LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
          LITERALS.MOVANO_BACK.CDK_ENV.stage +
          LITERALS.MOVANO_BACK.CDK_ENV.V3 +
          `sales?limit=${salesPerPage}&page=${page}&timeFrame=${timeFrame}&sortBy=${sortedBy}&sortMode=${sortedMode}&flgVip=true`,
        { headers: this.headers },
      );
    }
  }

  public getVipsBySoUserName(
    salesPerPage: number,
    page: number,
    soUserName: string,
  ): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales?limit=${salesPerPage}&page=${page}&soUserNameContains=${soUserName}&flgVip=true`,
      { headers: this.headers },
    );
  }

  public getVipsBySoNumber(
    salesPerPage: number,
    page: number,
    soNumber: string,
  ): Observable<any> {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales?limit=${salesPerPage}&page=${page}&soNumberContains=${soNumber}&flgVip=true`,
      { headers: this.headers },
    );
  }

  public getRmasHeaderData(timeFrame: string, dateStart?: string, dateEnd?: string) {
    if (dateStart && dateEnd) {
      return this.http.get<any>(
        LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
          LITERALS.MOVANO_BACK.CDK_ENV.stage +
          LITERALS.MOVANO_BACK.CDK_ENV.V3 +
          `sales/rma/stats?dateStart=${dateStart}&dateEnd=${dateEnd}&rma=salesPercentage&rmaReturns=compare&exchanges=compare`,
        { headers: this.headers },
      );
    }

    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales/rma/stats?timeFrame=${timeFrame}&rma=salesPercentage&rmaReturns=compare&exchanges=compare`,
      { headers: this.headers },
    );
  }

  public getRmasDonutsData(timeFrame: string, dateStart?: string, dateEnd?: string) {
    if (dateStart && dateEnd) {
      return this.http.get<any>(
        LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
          LITERALS.MOVANO_BACK.CDK_ENV.stage +
          LITERALS.MOVANO_BACK.CDK_ENV.V3 +
          `sales/rma/stats?dateStart=${dateStart}&dateEnd=${dateEnd}&rma=bySizes, byColors, byTypes`,
        { headers: this.headers },
      );
    }

    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales/rma/stats?timeFrame=${timeFrame}&rma=bySizes, byColors, byTypes`,
      { headers: this.headers },
    );
  }

  public getRmasDonutsBarsData(dateStart?: string, dateEnd?: string) {
    return this.http.get<any>(
      LITERALS.MOVANO_BACK.CDK_ENV.BACK_URL +
        LITERALS.MOVANO_BACK.CDK_ENV.stage +
        LITERALS.MOVANO_BACK.CDK_ENV.V3 +
        `sales/rma/stats?rma=bySizes, byColors, byTypes`,
      { headers: this.headers },
    );
  }
}
