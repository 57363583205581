export const cdkEnvironment = {
  production: true,
  BACK_URL: "https://fk6qbi152a.execute-api.us-west-2.amazonaws.com/",
  MMP: "mmp/",
  V1: "",
  V2: "v2/",
  V3: "v3/",
  stage: "dev/",
  apiKey: "9AssY0MZfn6r7DwczaXBAalIxx3RywOn1Qzed2pL",
};
